<template>
  <SimpleLayout>
  <div v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
    <div style="padding-bottom: 1rem;color: #888888;font-size: 1.2rem">
      免单卡增强
    </div>
    <div class="flex-def flex-warp">
      <div @click="dose(item)" class="flex-def flex-cCenter y-plugin-item y-pointer" v-for="(item,key) in business" :key="key">
        <img :src="elIcon(item.plugin)" class="icon">
        <div style="width: 80%">
          <div class="flex-def flex-cCenter flex-zBetween">
            <div style="font-size: 1.2rem;">{{ item.title }}</div>
          </div>
          <div class="y-desc">{{ item.desc }}</div>
        </div>
        <div class="flex-def flex-zEnd flex-cCenter y-desc" style="width: 20%">
          <div @click.stop="power(item)" :class="item.power ? 'close' : 'open'" class="flex-def flex-zCenter flex-cCenter power">
            <template v-if="!item.power">开<br>启</template>
            <template v-else>关<br>闭</template>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 1rem;color: #888888;font-size: 1.2rem;margin-top: 4rem">
      其它业务
    </div>
    <div class="flex-def flex-warp">
      <div @click="dose(item)" class="flex-def flex-cCenter y-plugin-item y-pointer" v-for="(item,key) in commission" :key="key">
        <img :src="elIcon(item.plugin)" class="icon">
        <div style="width: calc(100% - 4rem)">
          <div class="flex-def flex-cCenter flex-zBetween">
            <div style="font-size: 1.2rem;">{{ item.title }}</div>
          </div>
          <div class="y-desc" v-html="item.desc"></div>
        </div>
        <div @click.stop="power(item)" :class="item.power ? 'close' : 'open'" class="y-desc flex-def flex-zCenter flex-cCenter power">
          <template v-if="!item.power">开<br>启</template>
          <template v-else>关<br>闭</template>
        </div>
      </div>
    </div>
  </div>
  </SimpleLayout>
</template>

<script>

import SimpleLayout from "@/components/layouts/SimpleLayout";
import api from "@/api";
import {vuex} from "@/common/util";
export default {
  name: "manage",
  components: {SimpleLayout},
  data() {
    return {
      loading: false,
      business: [],
      commission: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    elIcon(name) {
      let str = "";
      if (process.env.NODE_ENV === "production"){
        str = `/static/web/icon/${name}.png`;
      }else {
        str = `./${process.env.BASE_URL}icon/${name}.png`
      }
      return str
    },
    load() {
      this.loading = true;
      this.$u.api.card.plugin.manage.added().then(res => {
        this.business = res.business;
        this.commission = res.commission;
        this.loading = false;
      })
    },
    dose(item) {
      if (!item.power) return this.power(item);
      this.$router.push(item.page);
    },
    power(item) {
      let con = this.$loading({text: "操作中请稍候..."});
      this.$u.api.card.plugin.manage.addedChange(item).then(() => {
        con.close()
        this.$message.success("操作成功");
        api.card.plugin.manage.enable().then(res=>{
          vuex("vuex_plugin",res);
        })
        this.load();
      }).catch(() => {
        con.close()
      });
    },
  }
}
</script>

<style scoped>

.icon{
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.y-plugin-item {
  background-color: #ededed;
  padding: 1rem;
  border-radius: .5rem;
  width: calc(33% - 5rem);
  margin: .5rem;
  box-shadow: 1px 1px 5px 1px #ededed;
  position: relative;
  overflow: hidden;
}

.power {
  background-color: #FFFFFF;
  width: 2rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  color: #FFFFFF;
  font-weight: 600;
}
.power.close{
  background-color: red;
}
.power.open{
  background-color: #3cc51f;
}
</style>